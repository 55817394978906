<template>
  <div class="customer-profiling-list-footer">
    <div class="customer-profiling-list-footer__limit">
      <div class="customer-profiling-list-footer__limit-label">
        Showing
      </div>
      <div
        ref="limitRootElement"
        class="customer-profiling-list-footer__limit-select"
      >
        <div
          class="customer-profiling-list-footer__limit-select-field"
          @click="toggleLimitMenu"
        >
          <div class="customer-profiling-list-footer__limit-select-field-label">
            {{ limit }}
          </div>
          <Icon
            class="customer-profiling-list-footer__limit-select-field-icon"
            name="chevron-selector"
          />
        </div>
        <div
          v-if="isLimitMenuOpen"
          class="customer-profiling-list-footer__limit-select-menu"
        >
          <div
            v-for="option in limitOptions"
            :key="option"
            class="customer-profiling-list-footer__limit-select-menu-option"
            @click="setLimit(option)"
          >
            <span class="customer-profiling-list-footer__limit-select-menu-option-label">
              {{ option }}
            </span>
            <Icon
              v-show="limit === option"
              class="customer-profiling-list-footer__limit-select-menu-option-iocn"
              name="check"
            />
          </div>
        </div>
      </div>
      <div class="customer-profiling-list-footer__limit-label">
        results per page
      </div>
      <div class="customer-profiling-list-footer__limit-label customer-profiling-list-footer__limit-label--is-muted">
        ({{ totalCount }} in total)
      </div>
    </div>
    <div class="customer-profiling-list-footer__page">
      <div
        v-for="option in pageOptions"
        :key="option.id"
        :class="[
          'customer-profiling-list-footer__page-option',
          { 'customer-profiling-list-footer__page-option--is-disabled': !option.value },
          { 'customer-profiling-list-footer__page-option--is-selected': page === option.value },
        ]"
        @click="setPage(option.value)"
      >
        <Icon
          v-if="option.icon"
          class="customer-profiling-list-footer__page-icon"
          :name="option.icon"
        />
        {{ option.label }}
      </div>
    </div>
  </div>
</template>

<script>
import { cloneDeep, ceil } from 'lodash';
import { ref, computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { onClickOutside } from '@vueuse/core';
import { getLimitOptions, getDefaultLimit, getDefaultPage } from '@/services/helpers/customer-profiling';
import { createNavigationItems } from '@/services/helpers/pagination';
import Icon from '@/components/common/Icon';

export default {
  components: {
    Icon,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const limitRootElement = ref(null);
    const isLimitMenuOpen = ref(false);
    const limitOptions = getLimitOptions();
    const limit = computed(() => store.getters['betTicker/allCustomersLimit']);
    const toggleLimitMenu = () => {
      isLimitMenuOpen.value = !isLimitMenuOpen.value;
    };
    const setLimit = (newLimit) => {
      const query = cloneDeep(route.query);
      if (newLimit === getDefaultLimit()) {
        delete query.limit;
      } else {
        query.limit = newLimit;
      }
      router.replace({
        ...route,
        query,
      });
      isLimitMenuOpen.value = false;
    };
    onClickOutside(limitRootElement, () => {
      isLimitMenuOpen.value = false;
    });

    const page = computed(() => store.getters['betTicker/allCustomersPage']);
    const totalCount = computed(() => store.getters['betTicker/allCustomersTotalCount']);
    const totalPages = computed(() => ceil(totalCount.value / limit.value));
    const pageOptions = computed(() => {
      const items = createNavigationItems({
        page: page.value,
        totalPages: totalPages.value,
      });
      if (items.length === 2) {
        return [
          items[0],
          { id: 2, label: '1', value: 1 },
          items[1],
        ];
      }
      return items;
    });
    const setPage = (newPage) => {
      if (!newPage) return;
      const query = cloneDeep(route.query);
      if (newPage === getDefaultPage()) {
        delete query.page;
      } else {
        query.page = newPage;
      }
      router.replace({
        ...route,
        query,
      });
    };

    return {
      limitRootElement,
      isLimitMenuOpen,
      toggleLimitMenu,
      limitOptions,
      limit,
      setLimit,
      pageOptions,
      page,
      totalCount,
      totalPages,
      setPage,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-list-footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  width: 100%;
  height: 63px;
  min-height: 63px;
  max-height: 63px;
  border-top: 1px solid #F0F0F0;
  padding: 0 16px;

  &__limit {
    display: flex;
    align-items: center;
    gap: 8px;

    &-label {
      font-family: 'Rubik', sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 16px;
      color: #191414;
      white-space: nowrap;

      &--is-muted {
        color: #6D6D6D;
      }
    }

    &-select {
      position: relative;
      width: 100%;
      height: 100%;

      &-field {
        display: flex;
        align-items: center;
        justify-content: space-between;
        font-family: 'Rubik', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        background-color: #fff;
        color: #191414;
        border: 1px solid #f0f0f0;
        padding: 8px;
        gap: 4px;
        border-radius: 4px;
        width: 80px;
        cursor: pointer;

        &:hover {
          background-color: #FAFAFA;
        }

        &-icon {
          fill: #191414;
        }
      }

      &-menu {
        position: absolute;
        bottom: calc(100%);
        background-color: #fff;
        color: #191414;
        box-shadow: 0 4px 8px rgba(0,0,0,.08);
        border-radius: 4px;
        margin-bottom: 4px;
        padding: 4px 0;
        border: 1px solid #f0f0f0;
        min-width: 100%;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
        z-index: 1000;
        overflow: hidden;

        &-option {
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding: 0 8px;
          width: 100%;
          height: 32px;
          min-height: 32px;
          max-height: 32px;
          white-space: nowrap;
          cursor: pointer;

          &:hover {
            background-color: #fafafa;
          }

          .icon {
            width: 16px;
            height: 16px;
            padding: 2px;
            stroke: #000;
          }
        }
      }
    }
  }

  &__page {
    display: flex;
    align-items: center;
    height: 100%;

    &-option {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 24px;
      height: 24px;
      min-height: 24px;
      max-height: 24px;
      font-family: 'Rubik', sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      cursor: pointer;
      user-select: none;
      color: #6d6d6d;
      stroke: #6d6d6d;
      padding: 0 4px;

      &:hover {
        color: #323232;
        stroke: #323232;
      }

      &--is-disabled {
        cursor: default;
        user-select: initial;
        color: #A9A9A9;
        stroke: #A9A9A9;

        &:hover {
          color: #A9A9A9;
          stroke: #A9A9A9;
        }
      }

      &--is-selected {
        color: #191414;
        stroke: #191414;
      }
    }

    &__icon {
      stroke: inherit;

      svg {
        stroke: inherit;
      }
    }
  }
}
</style>
