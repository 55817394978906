<template>
  <div class="customer-profiling-list-header">
    <div class="customer-profiling-list-header__operators-section">
      <CustomerProfilingListHeaderOperatorFilter
        :model-value="selectedOperator"
        :options="operatorOptions"
        @update:model-value="selectOperator"
      />
    </div>
    <div class="customer-profiling-list-header__actions-section">
      <CustomerProfilingListHeaderFlagFilter
        :model-value="isFlagged"
        @update:model-value="setIsFlagged"
      />
      <div class="customer-profiling-list-header__actions-group">
        <CustomerProfilingListHeaderSearchFilter
          :model-value="searchQuery"
          @update:model-value="setSearchQuery"
        />
        <Button
          icon="three-columns"
          @click="openColumnsSidebar"
        >
          Columns
        </Button>
        <Button
          icon="filters"
          @click="openFiltersSidebar"
        >
          Filters
        </Button>
      </div>
      <div
        class="customer-profiling-list-header__separator"
      />
      <Button
        icon="refresh-ccw-1"
        :loading="isLoading"
        @click="reloadData"
      >
        Reload data
      </Button>
    </div>
  </div>
</template>

<script>
import { cloneDeep, find, filter } from 'lodash';
import { computed } from 'vue';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import {
  FilterCondition,
  serializeFilterQuery,
} from '@/services/helpers/filters';
import {
  CustomerListSidebar,
  CustomerListFilter,
  getAvailableOperators,
  getDefaultOperator,
} from '@/services/helpers/customer-profiling';
import Button from '@/components/common/Button';
import CustomerProfilingListHeaderOperatorFilter from './CustomerProfilingListHeaderOperatorFilter';
import CustomerProfilingListHeaderFlagFilter from './CustomerProfilingListHeaderFlagFilter';
import CustomerProfilingListHeaderSearchFilter from './CustomerProfilingListHeaderSearchFilter';

const DEFAULT_OPERATOR_ID = getDefaultOperator()?.id || '';

export default {
  components: {
    Button,
    CustomerProfilingListHeaderOperatorFilter,
    CustomerProfilingListHeaderFlagFilter,
    CustomerProfilingListHeaderSearchFilter,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();

    const isLoading = computed(() => store.getters['betTicker/allCustomersIsLoading']);
    const allFilters = computed(() => store.getters['betTicker/allCustomersFilters']);

    const operatorOptions = computed(() => getAvailableOperators(store.getters.operatorsList));
    const selectedOperator = computed(() => find(allFilters.value, ({ id: CustomerListFilter.OPERATOR }))?.value || '');
    const selectOperator = (newOperator) => {
      const newFilters = filter(allFilters.value, (filterObject) => filterObject.id !== CustomerListFilter.OPERATOR);
      if (newOperator !== DEFAULT_OPERATOR_ID) {
        newFilters.push({
          id: CustomerListFilter.OPERATOR,
          condition: {
            id: FilterCondition.EQUAL,
          },
          value: newOperator,
        });
      }
      const { page, ...query } = cloneDeep(route.query);
      query.filter = serializeFilterQuery(newFilters);
      router.replace({
        ...route,
        query,
      });
    };

    const isFlagged = computed(() => find(allFilters.value, ({ id: CustomerListFilter.FLAGGED }))?.value);
    const setIsFlagged = (newIsFlagged) => {
      const newFilters = filter(allFilters.value, (filterObject) => filterObject.id !== CustomerListFilter.FLAGGED);
      if (newIsFlagged) {
        newFilters.push({
          id: CustomerListFilter.FLAGGED,
          condition: {
            id: FilterCondition.EQUAL,
          },
          value: true,
        });
      }
      const { page, ...query } = cloneDeep(route.query);
      query.filter = serializeFilterQuery(newFilters);
      router.replace({
        ...route,
        query,
      });
    };

    const searchQuery = computed(() => find(allFilters.value, ({ id: CustomerListFilter.SEARCH }))?.value || '');
    const setSearchQuery = (newSearchQuery) => {
      const newFilters = filter(allFilters.value, (filterObject) => filterObject.id !== CustomerListFilter.SEARCH);
      if (newSearchQuery.length) {
        newFilters.push({
          id: CustomerListFilter.SEARCH,
          condition: {
            id: FilterCondition.EQUAL,
          },
          value: newSearchQuery,
        });
      }
      const { page, ...query } = cloneDeep(route.query);
      query.filter = serializeFilterQuery(newFilters);
      router.replace({
        ...route,
        query,
      });
    };

    const openColumnsSidebar = () => {
      store.dispatch('betTicker/setAllCustomersSidebar', CustomerListSidebar.COLUMNS);
    };
    const openFiltersSidebar = () => {
      store.dispatch('betTicker/setAllCustomersSidebar', CustomerListSidebar.FILTERS);
    };
    const reloadData = () => {
      store.dispatch('betTicker/reloadAllCustomers');
    };

    return {
      isLoading,
      operatorOptions,
      selectedOperator,
      selectOperator,
      isFlagged,
      setIsFlagged,
      searchQuery,
      setSearchQuery,
      openColumnsSidebar,
      openFiltersSidebar,
      reloadData,
    };
  },
};
</script>

<style lang="scss">
.customer-profiling-list-header {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  width: 100%;
  height: 56px;
  min-height: 56px;
  max-height: 56px;
  padding: 0 16px;
  border-bottom: 1px solid #F0F0F0;
  overflow-x: auto;

  &__operators-section {
    height: 100%;
  }

  &__actions-section {
    display: flex;
    align-items: center;
    gap: 12px;
    height: 100%;
    padding: 12px 0;
  }

  &__actions-group {
    display: flex;
    align-items: center;
    gap: 4px;
    height: 100%;
  }

  &__separator {
    background-color: #F0F0F0;
    width: 1px;
    min-width: 1px;
    max-width: 1px;
    height: 100%;
  }
}
</style>
